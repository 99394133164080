import { flatten } from 'lodash'
import { Expression } from 'mapbox-gl'
import { NO_ENGINE } from '../components/map/group/GroupMap.vue'

export class LegendRepresentationItem {
    protected _color: string
    protected _label: string

    constructor(color: string, label: string) {
        this._color = color
        this._label = label
    }

    public get color(): string {
        return this._color
    }

    public get label(): string {
        return this._label
    }

    public get valueRepresentationTranslationObject(): Record<string, string> | undefined {
        return undefined
    }
}

export abstract class LegendRepresentation {
    protected _id: string
    protected _label: string
    protected _noEngineItem: LegendRepresentationItem
    protected _legendItems: LegendRepresentationItem[]

    constructor(id: string, label: string, noEngineItem: LegendRepresentationItem, items: LegendRepresentationItem[]) {
        this._id = id
        this._label = label
        this._noEngineItem = noEngineItem
        this._legendItems = items
    }

    public get id(): string {
        return this._id
    }

    public get label(): string {
        return this._label
    }

    public get noEngineItem(): LegendRepresentationItem {
        return this._noEngineItem
    }

    public get legendItems(): LegendRepresentationItem[] {
        return this._legendItems
    }

    public getLayerExpressionNoEngine(defaultColor: string): Expression {
        return [
            'case',
            ['==', ['feature-state', this._id], 'NO_ENGINE'],
            this._noEngineItem.color,
            this.getLayerExpression(defaultColor)
        ]
    }

    public abstract getLayerExpression(defaultColor: string): Expression

    public abstract getColorForValue(value: string | number | boolean | undefined | null): string
}

export class ValuesLegendRepresentationItem extends LegendRepresentationItem {
    private _value: number | string

    constructor(color: string, label: string, value: number | string) {
        super(color, label)
        this._value = value
    }

    public get value(): number | string {
        return this._value
    }

    public get valueRepresentationTranslationObject(): Record<string, string> {
        return { number: this.value.toString() }
    }
}

export class ValuesLegendRepresentation extends LegendRepresentation {
    private _items: ValuesLegendRepresentationItem[]

    constructor(
        id: string,
        label: string,
        noEngineItem: LegendRepresentationItem,
        items: ValuesLegendRepresentationItem[]
    ) {
        super(id, label, noEngineItem, items)
        this._items = items
    }

    public getLayerExpression(defaultColor: string): Expression {
        return [
            'match',
            ['feature-state', this._id],
            ...flatten(this._items.map((item) => [item.value, item.color])),
            defaultColor
        ]
    }

    public getColorForValue(value: string | number | null): string {
        const kpiValue = value != null ? value : -1

        const results = this._items.filter((item) => item.value == kpiValue)
        if (results.length != 1 || kpiValue == NO_ENGINE) {
            return this._noEngineItem.color
        }

        return results[0].color
    }
}

export class RangeLegendRepresentationItem extends LegendRepresentationItem {
    private _value: [number, number]

    constructor(color: string, label: string, value: [number, number]) {
        super(color, label)
        this._value = value
    }

    public get value(): [number, number] {
        return this._value
    }

    public get valueRepresentation(): string {
        const [min, max] = this._value
        if (min == -Number.MAX_VALUE) {
            return '< ' + max
        } else if (max == Number.MAX_VALUE) {
            return '> ' + min
        } else {
            return min + ' - ' + max
        }
    }

    public get valueRepresentationTranslationObject(): Record<string, string> {
        return { value: this.valueRepresentation }
    }
}

export class FirstRangeLegendRepresentationItem extends LegendRepresentationItem {
    private _hideInLegend?: boolean

    constructor(color: string, label: string, hideInLgend?: boolean) {
        super(color, label)
        this._hideInLegend = hideInLgend
    }

    public get hideInLegend(): boolean | undefined {
        return this._hideInLegend
    }
}

export class RangeLegendRepresentation extends LegendRepresentation {
    private _firstValue?: FirstRangeLegendRepresentationItem
    private _items: RangeLegendRepresentationItem[]

    constructor(
        id: string,
        label: string,
        noEngineItem: LegendRepresentationItem,
        items: RangeLegendRepresentationItem[],
        firstValue?: FirstRangeLegendRepresentationItem
    ) {
        super(id, label, noEngineItem, firstValue && !firstValue.hideInLegend ? [firstValue, ...items] : items)
        this._firstValue = firstValue
        this._items = items
    }

    public getLayerExpression(defaultColor: string): Expression {
        return [
            'step',
            ['number', ['feature-state', this._id], -1],
            this._firstValue ? this._firstValue.color : defaultColor,
            ...flatten(this._items.map((item) => [item.value[0], item.color]))
        ]
    }

    public getColorForValue(value: number | typeof NO_ENGINE | null): string {
        if (value == NO_ENGINE) {
            return this._noEngineItem.color
        }
        const kpiValue = value != null ? value : -1

        const result = this._items.find((item, index, array) => {
            const [min, max] = item.value
            return (
                (kpiValue >= min && index < array.length - 1 && kpiValue < max) ||
                index == array.length - 1
            )
        })

        if (result == undefined) {
            return this._noEngineItem.color
        }

        return result.color
    }
}

export class BooleanLegendRepresentation extends LegendRepresentation {
    private _noDataValue: LegendRepresentationItem
    private _trueValue: LegendRepresentationItem
    private _falseValue: LegendRepresentationItem

    constructor(
        id: string,
        label: string,
        noEngineItem: LegendRepresentationItem,
        noDataValue: LegendRepresentationItem,
        trueValue: LegendRepresentationItem,
        falseValue: LegendRepresentationItem
    ) {
        super(id, label, noEngineItem, [noDataValue, trueValue, falseValue])
        this._noDataValue = noDataValue
        this._trueValue = trueValue
        this._falseValue = falseValue
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    public getLayerExpression(defaultColor: string): Expression {
        return [
            'case',
            ['==', ['feature-state', this._id], null],
            this._noDataValue.color,
            ['boolean', ['feature-state', this._id], false],
            this._trueValue.color,
            this._falseValue.color
        ]
    }

    public getColorForValue(value: boolean | undefined | typeof NO_ENGINE | null): string {
        switch (value) {
            case true:
                return this._trueValue.color
            case false:
                return this._falseValue.color
            default:
                return this._noDataValue.color
        }
    }
}
